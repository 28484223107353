import {Inject, Injectable, Renderer2, RendererFactory2} from "@angular/core";
import {BehaviorSubject, Observable, Subject} from "rxjs";
import {ITenant} from "@core/models/ITenant";
import {HttpService, IApiResponse, LoginService, UserService} from "@app/core";
import {PrimeNGConfig} from "primeng/api";
import {DOCUMENT} from "@angular/common";
import {Title} from "@angular/platform-browser";
import {
  EInterfaceInputStyle,
  EInterfaceLayout,
  EInterfaceMenu,
  EInterfaceSize,
  EInterfaceToastPosition
} from "@shared/enums/EInterface";
import {IInterface} from "@shared/models/IInterface";

@Injectable({
  providedIn: "root"
})
export class ConfigService {
  public showOneLogin = true;
  public config: IInterface = {
    menuLogoUrl: "",
    frontLogoUrl: "",
    menuLogoDarkThemeUrl: "",
    frontText: "",
    frontFootNote: "",
    frontLogoDarkThemeUrl: "",
    size: EInterfaceSize.medium,
    primaryColor: "#2196F3",
    borderRadius: "3px",
    disableOpacity: 0.6,
    menuBackground: "#ffffff",
    menuTextColor: "rgba(0, 0, 0, 0.87)",
    menuActiveColor: "rgba(0, 0, 0, 0.87)",
    menuHoverBackground: "rgba(0, 0, 0, .04)",
    menuBorderRadius: "0px",
    textColor: "#495057",
    messagesError: "#73000c",
    messagesWarning: "#6d5100",
    messagesInfo: "#044868",
    messagesSuccess: "#224a23",
    messagesErrorBackground: "#FFCDD2",
    messagesWarningBackground: "#FFECB3",
    messagesInfoBackground: "#B3E5FC",
    messagesSuccessBackground: "#C8E6C9",
    buttonBackground: "#2196F3",
    buttonHoverBackground: "#0d89ec",
    buttonFocusBackground: "#0d89ec",
    buttonText: "#fff",
    buttonHoverText: "#fff",
    buttonBorder: "#2196F3",
    buttonHoverBorder: "#0d89ec",
    buttonFocusBorder: "#0b7ad1",
    buttonSecondaryBackground: "#607D8B",
    buttonSecondaryHoverBackground: "#56717d",
    buttonSecondaryFocusBackground: "#56717d",
    buttonSecondaryText: "#ffffff",
    buttonSecondaryHoverText: "#ffffff",
    buttonSecondaryBorder: "#607D8B",
    buttonSecondaryHoverBorder: "#56717d",
    buttonSecondaryFocusBorder: "#beccd2",
    surfaceHeader: "#f8f9fa",
    surfaceContent: "#ffffff",
    surfaceBorder: "#dee2e6",
    formFilledBackground: "#f8f9fa",
    formFilledHoverBackground: "#f8f9fa",
    formFilledFocusBackground: "#ffffff",
    formBackground: "#ffffff",
    formHoverBackground: "#ffffff",
    formFocusBackground: "#ffffff",
    formBorder: "#ced4da",
    formHoverBorder: "#2196F3",
    formFocusBorder: "#2196F3",
    errorColor: "#D32F2F",
    primaryColorText: "#ffffff",
    highlightBackground: "#E3F2FD",
    highlightColor: "#495057",
    hoverBackground: "#dee2e6",
    loginBackground: "#eff3f8",
    loginText: "#495057",
    loginButton: "#14B8A6",
    loginButtonHover: "#0D9488",
    loginButtonColor: "#ffffff",
    loginLeftBackground: "#8daacf",
    loginLeftText: "#eff3f8",
    darkTheme: "md-dark-indigo",
    frontFootNoteMobile: false,
    inputStyle: EInterfaceInputStyle.filled,
    layout: EInterfaceLayout.wide,
    menuMode: EInterfaceMenu.horizontal,
    theme: "saga-blue",
    frontTextMobile: false,
    loginLeftBackgroundImg: "",
    loginLeftBackgroundImgUrl: "",
    loginLeftImg: "",
    loginLeftImgUrl: "",
    toastPosition: EInterfaceToastPosition.bottomRight,
    loginLeftImgUrlMobile: false
  };
  private _configUpdate = new Subject<IInterface>();
  public configUpdate$ = this._configUpdate.asObservable();
  private _logo: BehaviorSubject<string> = new BehaviorSubject("assets/images/logo.svg");
  public logo$: Observable<string> = this._logo.asObservable();
  private _menuLogo: BehaviorSubject<string> = new BehaviorSubject("assets/images/logo.svg");
  public menuLogo$: Observable<string> = this._menuLogo.asObservable();
  private _isDark = new BehaviorSubject(false);
  public isDark$ = this._isDark.asObservable();
  private _layout = new BehaviorSubject(EInterfaceLayout.wide);
  public layout$ = this._layout.asObservable();
  private _excludeVariables: string[] = [
    "menuLogoUrl",
    "frontLogoUrl",
    "menuLogoDarkThemeUrl",
    "frontText",
    "frontFootNote",
    "frontLogoDarkThemeUrl",
    "size",
    "darkTheme",
    "frontFootNoteMobile",
    "inputStyle",
    "layout",
    "menuMode",
    "theme",
    "frontTextMobile",
    "loginLeftBackgroundImg",
    "loginLeftBackgroundImgUrl",
    "loginLeftImg",
    "loginLeftImgUrl",
    "toastPosition"
  ];
  private _rerender: Renderer2;
  private _subdomain: string;

  constructor(
    private _http: HttpService,
    private _primengConfig: PrimeNGConfig,
    private _api: LoginService,
    private _user: UserService,
    private _title: Title,
    rendererFactory: RendererFactory2,
    @Inject(DOCUMENT) private document
  ) {
    this._rerender = rendererFactory.createRenderer(null, null);
    this._user.user$.pipe().subscribe(val => {
      this.updateScale();
      this.updateLayout();
    });
  }

  public get isDark() {
    return this._isDark.getValue();
  }

  public get textColor() {
    return this._isDark.getValue() ? "white" : this.config.textColor;
  }

  public async loadTenant(): Promise<ITenant> {
    const domain = window.location.hostname;
    if (domain.indexOf(".") < 0 || domain.split(".")[0] === "www") {
      this._subdomain = " ";
    } else {
      this._subdomain = domain.split(".")[0];
    }
    if (this._subdomain === " ") {
      this._subdomain = "dev";
    }
    this.showOneLogin = ["dev", "wwb"].includes(this._subdomain);
    return new Promise<ITenant>((resolve) => {
      this._http.get("common/getTenant", {subdomain: this._subdomain})
        .subscribe((response: IApiResponse<ITenant>) => {
            if (response.success && response.data) {
              this._initConfig(response.data.uiSettings);
              if (response.data.name !== "(default)") {
                this._title.setTitle(response.data.name);
              }
              resolve(response.data);
            }
          }
        );
    }).catch((error: any) => error || "Server error");
  }

  public switchThemeMode() {
    this._isDark.next(!this._isDark.getValue());
    this._saveThemeMode();
  }

  public updateTheme(important = false) {
    let dark = false;
    if (this._user.getUser && this._user.getUser.userInfo) {
      dark = this._user.getUser.userInfo.useDarkTheme;
    } else if (JSON.parse(localStorage.getItem("rv-dark"))) {
      dark = JSON.parse(localStorage.getItem("rv-dark"));
    }
    localStorage.setItem("rv-dark", JSON.stringify(dark));
    if (this._isDark.getValue() !== dark || important) {
      this._isDark.next(dark);
      let themeElement = document.getElementById("theme-css");
      const themeName = (this._isDark.getValue()) ? this.config.darkTheme : this.config.theme;
      themeElement.setAttribute("href", `assets/theme/${themeName}/theme.css`);
      this._applyStyles();
    }
    this._checkLogo();
  }

  public updateScale() {
    if (this.config && this.config.size || this.config.size === 0) {
      let _size = this.config.size;
      if (this._user.getUser && this._user.getUser.userInfo && this._user.getUser.userInfo.size !== null) {
        _size = this._user.getUser.userInfo.size;
      }
      switch (_size) {
        case EInterfaceSize.small:
          document.documentElement.style.fontSize = "12px";
          break;
        case EInterfaceSize.medium:
          document.documentElement.style.fontSize = "14px";
          break;
        case EInterfaceSize.large:
          document.documentElement.style.fontSize = "15px";
          break;
      }
    }
  }

  public updateLayout() {
    if (this.config && this.config.layout || this.config.layout === 0) {
      let _layout = this.config.layout;
      if (this._user.getUser && this._user.getUser.userInfo && this._user.getUser.userInfo.layout !== null) {
        _layout = this._user.getUser.userInfo.layout;
      }
      this._layout.next(_layout);
    }
  }

  private _initConfig(config: IInterface) {
    this.config = config;
    this._primengConfig.ripple = true;
    this.updateScale();
    this.updateLayout();
    if (this.config.inputStyle === EInterfaceInputStyle.filled) {
      document.documentElement.classList.add("p-input-filled");
      document.documentElement.classList.remove("p-input-outlined");
    } else {
      document.documentElement.classList.add("p-input-outlined");
      document.documentElement.classList.remove("p-input-filled");
    }
    this.updateTheme(true);
    this._configUpdate.next(this.config);
  }

  private _applyStyles() {
    if (this._isDark.getValue() || !this.config) {
      const existedStyles = this.document.getElementById("domain-variables");
      if (existedStyles) {
        const head = this.document.getElementsByTagName("head")[0];
        this._rerender.removeChild(head, existedStyles);
      }
      return;
    } else {
      var template = `:root {`;
      Object.keys(this.config).map(key => {
        if (!this._excludeVariables.includes(key)) {
          template = template + ` --${key}: ${this.config[key]};`;
        }
      });
      template = template + "}";
      const head = this.document.getElementsByTagName("head")[0];
      const existedStyles = this.document.getElementById("domain-variables");

      if (existedStyles) {
        this._rerender.removeChild(head, existedStyles);
      }

      const styleElement = this._rerender.createElement("style");
      this._rerender.setAttribute(styleElement, "id", "domain-variables");
      this._rerender.setAttribute(styleElement, "type", "text/css");
      const stylesR = this._rerender.createText(template);
      this._rerender.appendChild(styleElement, stylesR);
      this._rerender.appendChild(head, styleElement);
    }
  }

  private _saveThemeMode() {
    this._api.profileThemeUpdate(this._isDark.getValue()).pipe()
      .subscribe(() => {
        const user = this._user.getUser;
        user.userInfo.useDarkTheme = this._isDark.getValue();
        this._user.setUser = user;
        this.updateTheme(true);
      });
  }

  private _checkLogo() {
    const logo = this._isDark.getValue() ? this.config.frontLogoDarkThemeUrl : this.config.frontLogoUrl;
    this._logo.next(logo ? logo : "assets/images/logo.svg");
    const menuLogo = this._isDark.getValue() ? this.config.menuLogoDarkThemeUrl : this.config.menuLogoUrl;
    this._menuLogo.next(menuLogo ? menuLogo : "assets/images/logo.svg");
  }
}
