import {Injectable} from "@angular/core";
import {Subject} from "rxjs";
import {HttpService} from "@core/services/http.service";
import {tap} from "rxjs/operators";
import {UserService} from "@core/services/user.service";
import {TranslateService} from "@ngx-translate/core";
import {environment} from "@environments/environment";

interface Label {
  caption: string;
  idTranslation: number;
  tooltip: string;
  controlName: string;
  mandatory: boolean;
}

@Injectable()
export class LabelsService {
  public labels: {[key: string]: Label} = {};
  private _labels: Subject<{[key: string]: Label}> = new Subject();

  constructor(private user: UserService, private http: HttpService, private translate: TranslateService) {
  }

  private _lastGroupId;

  get lastGroupId() {
    return this._lastGroupId;
  }

  public loadDictionary() {
    const currentUser = this.user.getUser;
    const region = currentUser.region;
    this._lastGroupId = currentUser.currentGroup.idGroup;

    return this.http.get(`common/getTextLabelsDict`).pipe(
      tap(res => {
        this.translate.setTranslation(region, res.data);
        this.translate.use(region);
        this._labels.next(res.data);
        this.labels = res.data;
        if (!environment.production) {
          console.group("Translations");
          console.dir(res.data);
          console.groupEnd();
        }
      })
    );
  }
}
