import {Injectable} from "@angular/core";
import {BehaviorSubject, Observable} from "rxjs";
import {MenuItem} from "primeng/api";

@Injectable()
export class BreadcrumbService {
  private breadcrumbs: BehaviorSubject<MenuItem[]> = new BehaviorSubject([]);
  public breadcrumbs$ = this.breadcrumbs.asObservable();
  public title$: BehaviorSubject<string> = new BehaviorSubject("");
  public home: BehaviorSubject<MenuItem> = new BehaviorSubject({icon: "pi pi-home", routerLink: "/panel/dashboard"});
  public home$: Observable<MenuItem> = this.home.asObservable();

  constructor() {
  }

  setBreadcrumbs(breadcrumbs: MenuItem[], title = "", onlyHeader = false): void {
    if (onlyHeader) {
      this.breadcrumbs.next([]);
    } else {
      this.breadcrumbs.next(breadcrumbs);
    }
    this.title$.next(title);
  }

  setHome(home: MenuItem): void {
    this.home.next(home);
  }
}
