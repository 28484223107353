import {Injectable} from "@angular/core";
import {BehaviorSubject, Observable} from "rxjs";
import {IGroup} from "@core/models/IGroup";
import {HttpService, IUser, LoaderService, LoginService, PermissionService} from "@app/core";
import {distinctUntilChanged, filter, finalize, map, switchMap, tap} from "rxjs/operators";
import {IClient} from "@core/models/IClient";
import {AuthService} from "@core/services/auth.service";
import {Router} from "@angular/router";
import {PermissionCodes} from "@shared/enums/PermissionCodes";
import {FormConfigService} from "@shared/service/form-config.service";
import {TableConfigService} from "@shared/service/table-config.service";

@Injectable()
export class UserService {
  public currentGroup$: Observable<IGroup>;
  public perms = PermissionCodes;
  private _user: BehaviorSubject<IUser> = new BehaviorSubject(this._getUserFromStorage() || null);
  public user$: Observable<IUser> = this._user.asObservable().pipe(filter(u => !!u));
  private _currentClient: BehaviorSubject<IClient> = new BehaviorSubject(this._getClientFromStorage() || new IClient());
  public currentClient$: Observable<IClient> = this._currentClient.asObservable().pipe(distinctUntilChanged());

  constructor(
    private _http: HttpService,
    private _permission: PermissionService,
    private _auth: AuthService,
    private _login: LoginService,
    private _loader: LoaderService,
    private _router: Router,
    private _formConfig: FormConfigService,
    private _tableConfig: TableConfigService
  ) {
    this.currentGroup$ = this._user.asObservable().pipe(
      map(user => (user && user.currentGroup) ? user.currentGroup : null),
      distinctUntilChanged()
    );
  }

  public set setUser(user: IUser) {
    localStorage.setItem("currentUser", JSON.stringify(user));
    if (user) {
      localStorage.setItem("currentGroup", JSON.stringify(user.currentGroup));
      if (!!user.userInfo.defaultSearchScope) {
        // this._store.dispatch(setFiltersScope({scope: user.userInfo.defaultSearchScope}));
      }
      if (user.isGuest && this._permission.checkAccess(this.perms.InsuredTree)) {
        // this._store.dispatch(setFiltersIdTree({idTree: user.currentGroup.idTree}));
      }
    }
    this._user.next(user);
  }

  public get getUser(): IUser {
    return this._user.getValue();
  }

  public get getGroup(): IGroup {
    return this._user.getValue().currentGroup || null;
  }

  public set setCurrentClient(client: IClient) {
    localStorage.setItem("currentClient", JSON.stringify(client));
    this._currentClient.next(client);
  }

  public get getCurrentClient(): IClient {
    return this._currentClient.getValue();
  }

  public set setEntity(group: IGroup) {
    const user = this._user.getValue();
    user.currentGroup = group;
    this.setUser = user;
    this._router.navigate(["panel/dashboard"]);
  }

  public changeGroup(group: IGroup): Observable<any> {
    const changeGr = this._http.get("auth/changeGroup", {
      idGroup: group.idGroup,
      refreshToken: this._auth.refreshToken
    });
    if (group.idLatestClient && group.idLatestClient !== "0") {
      const getClient = this._http.get(`insured/getDetail`, {idInsured: group.idLatestClient});
      return changeGr.pipe(
        tap((login) => this._auth.updateTokens(login.data)),
        switchMap(login => getClient.pipe(map(client => [login.data, client.data]))),
        tap(([login, client]) => {
          const {permissions} = login;
          this._permission.setPermissions(permissions);
          const user: IUser = this.getUser;
          this.setUser = {...user, ...login, currentGroup: group};
          this.setCurrentClient = client;
          this._formConfig.fetchFormConfigs();
          this._tableConfig.fetchTableConfigs();
          this._router.navigate(["panel/dashboard"]);
        })
      );
    } else {
      return changeGr.pipe(
        tap((login) => this._auth.updateTokens(login.data)),
        tap((login) => {
          const {permissions} = login.data;
          this._permission.setPermissions(permissions);
          const user: IUser = this.getUser;
          this.setUser = {...user, ...login.data, currentGroup: group};
          this._formConfig.fetchFormConfigs();
          this._tableConfig.fetchTableConfigs();
          this._router.navigate(["panel/dashboard"]);
        })
      );
    }
  }

  public acceptPolicy() {
    return this._http.post("auth/acceptPrivacyPolicy");
  }

  public logout(): void {
    this._loader.show();
    if (this._auth.refreshToken) {
      this._login.logout(this._auth.refreshToken).pipe(finalize(() => {
        const darkMode = JSON.parse(localStorage.getItem("rv-dark"));
        localStorage.clear();
        sessionStorage.clear();
        localStorage.setItem("rv-dark", JSON.stringify(darkMode));
        this.setUser = null;
        this._auth.clear();
        // @ts-ignore
        Chatra("kill");
        this._router.navigate(["/"]);
        this._loader.hide();
      }))
        .subscribe();
    } else {
      const darkMode = JSON.parse(localStorage.getItem("rv-dark"));
      localStorage.clear();
      sessionStorage.clear();
      localStorage.setItem("rv-dark", JSON.stringify(darkMode));
      this.setUser = null;
      this._auth.clear();
      // @ts-ignore
      Chatra("kill");
      this._router.navigate(["/"]);
    }
  }

  public hasPermission(perm: number): boolean {
    return this._user.getValue().permissions.some(permission => permission === perm);
  }

  public getTimeTrackStatus(): number {
    return (this._user.getValue() && this._user.getValue().currentGroup && this._user.getValue().currentGroup.timetrackStatus)
      ? this._user.getValue().currentGroup.timetrackStatus
      : 0;
  }

  // region Private
  private _getClientFromStorage(): IClient {
    return JSON.parse(localStorage.getItem("currentClient"));
  }

  private _getUserFromStorage(): IUser {
    return JSON.parse(localStorage.getItem("currentUser"));
  }

  // endregion
}
