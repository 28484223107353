import {Injectable} from "@angular/core";
import {HttpService} from "@app/core";
import {Observable} from "rxjs";
import {IApiResponse} from "@shared/models/IApiResponse";
import {ETableConfig} from "@shared/enums/ETableConfig";
import {ITableConfig} from "@shared/models/ITableConfig";

@Injectable({
  providedIn: "root"
})
export class TableSettingsApiService {
  private path = "group/table-settings";

  constructor(private _http: HttpService) {
  }

  // DESC: Get group form settings
  public getSettings(tableType: ETableConfig = ETableConfig.All): Observable<IApiResponse<ITableConfig[]>> {
    return this._http.get(`${this.path}`, {tableType});
  }

  // DESC: Update group table settings
  public updateSettings(data: ITableConfig[]): Observable<IApiResponse<null>> {
    return this._http.post(`${this.path}`, {data: data});
  }
}
