export enum ETableConfig {
  All = 0,
  Insurance = 1,
  Claims = 2,
  ClientClaims = 3,
  Object = 4,
}

export enum ETableDataType {
  String,
  Date,
  Money,
  Number,
  Boolean,
  Controls
}