import {Injectable} from "@angular/core";
import {MessageService} from "primeng/api";
import {TranslateService} from "@ngx-translate/core";
import {IErrorResponse} from "@app/core";

type Severities = "success" | "info" | "warn" | "error";

@Injectable({
  providedIn: "root"
})
export class NotificationsService {
  private ERRORS = {
    "RV-0001": "Required field",
    "RV-0002": "Wrong format",
    "RV-0003": "Invalid value",
    "RV-0004": "Object with given ID was not found",
    "RV-0005": "Object can't be deleted",
    "RV-1001": this._t.instant("phoneNumberError.caption"),
    "RV-1002": "Email already exist",
    "RV-1003": "Something wrong with external service",
    "RV-2001": this._t.instant("jobQueeWait.caption"),
    "RV-2002": "Job status update failed",
    "RV-2003": "Job isn't implemented",
    "RV-2004": "Receivers file not found.",
    "RV-2005": "Receivers file parsing error",
    "RV-3001": "User Email or password is incorrect",
    "RV-3002": "Such login doesn't exist",
    "RV-3003": "Login is deactivated",
    "RV-3004": "Password is invalid",
    "RV-3005": "Old password is incorrect",
    "RV-3006": "New password is already used",
    "RV-3007": "Download and register in Authy app to complete registration",
    "RV-3008": "Can`t register new user in Authy. Phone number or Email is not valid!",
    "RV-3009": "Role with such name is already exist in system",
    "RV-3011": "Reset token is expired",
    "RV-4001": "Cant delete product attribute",
    "RV-4002": this._t.instant("deleteError.tooltip"),
    "RV-4003": "Record with this value is already exists",
    "RV-5001": "Document name mismatch",
    "RV-5002": "File is not attached",
    "RV-6001": "End date must be greater than start date more than one year",
    "RV-6002": "Template empty",
    "RV-7001": "Only one type can be selected",
    "RV-8001": "Invalid insured parent",
    "RV-8002": this._t.instant("insuredDeleteWarning.caption"),
    "RV-8003": "Not allowed to update tree",
    "RV-8004": "Invalid date",
    "RV-8005": "Team member already exist",
    "RV-8006": "Team member has commission",
    "RV-9001": "No data from token",
    "RV-9002": this._t.instant("certificateImportValidateError.caption"),
    "RV-9003": "Can't create certificate import",
    "RV-9004": this._t.instant("import_Parser_Partial.caption"),
    "RV-9005": "Error in segmentation values",
    "RV-9006": "Task with type automated workflow is already running",
    "RV-10001": "Cant delete"
  };

  constructor(
    private _messageService: MessageService,
    private _t: TranslateService
  ) {
  }

  public notify(severity: Severities, summary: string, detail: string, key: string = "private") {
    this._messageService.add({severity, summary, detail, key});
  }

  public created() {
    this.notify("success", "", this._t.instant("create_Confirm.caption"));
  }

  public updated() {
    this.notify("success", "", this._t.instant("updateSucceeded.caption"));
  }

  public showErrors(err: IErrorResponse, errorMessage = "", key = "private") {
    if (err.status === 403) {
      return;
    }
    
    if (err.status === 500) {
      this.notify("error", "", this.getErrorMessage("system_19.caption"), key);
      return;
    }

    if (err.error && err.error.errors) {
      const filteredErrors = err.error.errors.filter(er => this.ERRORS.hasOwnProperty(er.code));
      if (filteredErrors.length) {
        filteredErrors.forEach(er => {
          this.notify("error", "", this.getErrorMessage(this.ERRORS[er.code]), key);
        });
      } else if (err.error.errors[0] && err.error.errors[0].description) {
        this.notify("error", "", this.getErrorMessage(err.error.errors[0].description), key);
      } else if (err.status === 422) {
        this.notify("error", "", this.getErrorMessage("Form filled with wrong value"), key);
      } else {
        this.notify("error", "", this.getErrorMessage((errorMessage) ? errorMessage : "system_19.caption"), key);
      }
      return;
    }

    if (errorMessage) {
      this.notify("error", "", this.getErrorMessage(errorMessage), key);
      return;
    }

    if (err.status === 422) {
      this.notify("error", "", this.getErrorMessage("Form filled with wrong value"), key);
    } else {
      this.notify("error", "", this.getErrorMessage("system_19.caption"), key);
    }
  }

  private getErrorMessage(text: string): string {
    if (text.includes(".caption") || text.includes(".tooltip")) {
      return !localStorage.getItem("currentUser") ? "Error" : this._t.instant(text);
    }

    return text;
  }

}
