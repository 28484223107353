import {Component, Input, OnInit, Optional} from "@angular/core";
import {Observable, of} from "rxjs";
import {LoaderService} from "@app/core";
import {AsyncPipe} from "@angular/common";

@Component({
  selector: "app-loader",
  templateUrl: "./loader.component.html",
  styleUrls: ["./loader.component.scss"],
  standalone: true,
  imports: [AsyncPipe]
})
export class LoaderComponent implements OnInit {
  show: boolean | Observable<boolean>;

  constructor(@Optional() public loader: LoaderService) {
  }

  @Input()
  set showState(val: boolean) {
    this.show = of(val);
  }

  ngOnInit() {
    if (this.show === undefined) {
      this.show = this.loader.showState$;
    }
  }
}
