import {Injectable} from "@angular/core";
import {BehaviorSubject} from "rxjs";


@Injectable()
export class PermissionService {
  private permissions = new BehaviorSubject(this.getFromStorage() || []);
  public permissions$ = this.permissions.asObservable();

  constructor() {
  }

  setPermissions(permissions: number[]) {
    localStorage.setItem("insPermissions", JSON.stringify(permissions));
    this.permissions.next(permissions);
  }

  enabled(pValue: number): boolean {
    const perms = this.permissions.getValue();
    return perms.findIndex(el => el === pValue) !== -1;
  }

  checkAccess(val: number | Array<number>, allRequired = false) {
    if (!val) {
      return true;
    }
    if (typeof val === "number") {
      return this.enabled(<number>val);
    } else {
      return (!allRequired) ? val.map(el => this.enabled(el)).includes(true) : val.every(el => this.enabled(el));
    }
  }

  private getFromStorage() {
    return JSON.parse(localStorage.getItem("insPermissions"));
  }
}
