export enum PermissionCodes {
  MenuUserDetails = 11,
  MenuUserChgPwd = 18,
  MenuUserLogout = 19,
  ChangeGroup = 21,
  Dashboard = 25,
  InsuredCreate = 31,
  InsuredDelete = 32,
  InsuredSearch = 33,
  InsuredTree = 34,
  InsuredUpdate = 35,
  InsuredDocManage = 36,
  InsuredEmailManage = 37,
  InsuredEmailRemove = 38,
  InsuredContactsManage = 39,
  InsuredFilter = 51,
  InsuredTeam = 5094,

  InsuranceCreate = 43,
  InsuranceFinance = 42,
  InsuranceEdit = 41,
  InsuranceUnderwriter = 44,
  InsuranceFileUpload = 45,
  InsuranceFileView = 46,
  InsuranceCertificatesView = 48,
  InsuranceCertificatesManage = 49,
  ClientBankAccountManage = 50,

  Localization = 91,

  InsuranceCriticalEdit = 401,

  MenuMainClients = 501,
  MenuMainInsurance = 502,
  MenuMainClaims = 503,
  MenuMainRiskManagement = 504,
  MenuMainReporting = 506,
  MenuMainAdmin = 507,
  MenuMainPermissions = 508,
  MenuMainUserManagement = 508,
  MenuMainEmails = 509,
  MenuMainInsuranceAdmin = 5021,
  MenuMainTaskManager = 5085,
  ClaimCreate = 5031,
  ClaimView = 5032,
  ClaimAdmin = 5033,
  ClaimReports = 5034,
  ClaimViewAll = 5035,
  ClaimPaymentDelete = 5036,

  AssetDefinitionManage = 5041,
  InsuredAssets = 5042,

  UserRoles = 5081,
  UserList = 5082,
  UserCreateDelete = 5083,
  ClientLogins = 5084,
  CreateGroup = 5084,
  FilesDeleteSettings = 5086,
  AutoRenewalsViewAll = 5087,
  BudgetReportViewAll = 5088,
  CreateRootFolder = 5089,
  BudgetReportViewAllNot = 5090,

  LicenceOwnerGroupManage = 5091,
  AutomatedWorkflow = 5092,
  ClientCreateSpecificDocFolders = 5093,
  SavedReports = 5096,
  ControlAgentInsurance = 5095,
  InsuredAccountReset = 5097,
  ImportDMR = 5098,
  ClientAddressView = 5099,
  ClientClaimsView = 5100,
  PremiumAndAuditsTabView = 5101,
  RmReport = 5102,
  AdminControl = 5103,
  SmsFeature = 5104,
  UpdateStartDate = 5105,
  AuditLog = 5106,
  FormConfigurator = 5107,
  InsuredMailsView = 5108,
  InsuredAccount = 5109,
  Tasks = 5110,
  TimeTracking = 5111,
}
