import {Injectable} from "@angular/core";
import {BehaviorSubject, Observable} from "rxjs";
import {IApiResponse} from "@app/core";
import {ETableConfig, ETableDataType} from "@shared/enums/ETableConfig";
import {
  ITableConfig,
  ITableConfigInfo,
  ITableConfigInfoList,
  ITableConfigList,
  ITableConfigWithInfo
} from "@shared/models/ITableConfig";
import {TableSettingsApiService} from "@shared/api/table-settings/table-settings-api.service";
import {TranslateService} from "@ngx-translate/core";

@Injectable({
  providedIn: "root"
})
export class TableConfigService {
  private _tableConfigsDefault: ITableConfigList = {
    [ETableConfig.Insurance]: [
      {
        order: 0,
        tableType: ETableConfig.Insurance,
        controlName: "insuranceProduct",
        show: true
      },
      {
        order: 1,
        tableType: ETableConfig.Insurance,
        controlName: "note",
        show: true
      },
      {
        order: 2,
        tableType: ETableConfig.Insurance,
        controlName: "insurancePolicyNumber",
        show: true
      },
      {
        order: 3,
        tableType: ETableConfig.Insurance,
        controlName: "insuranceCompany",
        show: true
      },
      {
        order: 4,
        tableType: ETableConfig.Insurance,
        controlName: "insuranceStart",
        show: true
      },
      {
        order: 5,
        tableType: ETableConfig.Insurance,
        controlName: "insuranceRenewal",
        show: true
      },
      {
        order: 6,
        tableType: ETableConfig.Insurance,
        controlName: "insuranceAnnualTotal",
        show: true
      },
      {
        order: 7,
        tableType: ETableConfig.Insurance,
        controlName: "insuranceWrittenTotal",
        show: true
      },
      {
        order: 8,
        tableType: ETableConfig.Insurance,
        controlName: "currency",
        show: true
      },
      {
        order: 9,
        tableType: ETableConfig.Insurance,
        controlName: "user_status",
        show: true
      },
      {
        order: 10,
        tableType: ETableConfig.Insurance,
        controlName: "controls",
        show: true
      },
      {
        order: 11,
        tableType: ETableConfig.Insurance,
        controlName: "insuranceFees",
        show: false
      },
      {
        order: 12,
        tableType: ETableConfig.Insurance,
        controlName: "insuranceAnnualPremium",
        show: false
      },
      {
        order: 13,
        tableType: ETableConfig.Insurance,
        controlName: "insuranceInstalments",
        show: false
      },
      {
        order: 14,
        tableType: ETableConfig.Insurance,
        controlName: "insuranceWrittenPremium",
        show: false
      },
      {
        order: 15,
        tableType: ETableConfig.Insurance,
        controlName: "insuranceOriginalStart",
        show: false
      },
      {
        order: 16,
        tableType: ETableConfig.Insurance,
        controlName: "renewal_Status",
        show: false
      }
    ],
    [ETableConfig.Claims]: [
      {
        order: 0,
        tableType: ETableConfig.Claims,
        controlName: "claim_ID",
        show: true
      },
      {
        order: 1,
        tableType: ETableConfig.Claims,
        controlName: "claim_IncidentDate",
        show: true
      },
      {
        order: 2,
        tableType: ETableConfig.Claims,
        controlName: "claim_IncidentType",
        show: true
      },
      {
        order: 3,
        tableType: ETableConfig.Claims,
        controlName: "claim_Insured",
        show: true
      },
      {
        order: 4,
        tableType: ETableConfig.Claims,
        controlName: "claim_IncidentSubType",
        show: true
      },
      {
        order: 5,
        tableType: ETableConfig.Claims,
        controlName: "reference",
        show: true
      },
      {
        order: 6,
        tableType: ETableConfig.Claims,
        controlName: "claim_Status",
        show: true
      },
      {
        order: 7,
        tableType: ETableConfig.Claims,
        controlName: "claim_Total_Estimate",
        show: true
      },
      {
        order: 8,
        tableType: ETableConfig.Claims,
        controlName: "latest_editing",
        show: true
      },
      {
        order: 9,
        tableType: ETableConfig.Claims,
        controlName: "controls",
        show: true
      },
      {
        order: 10,
        tableType: ETableConfig.Claims,
        controlName: "claim_Contact_Email",
        show: false
      },
      {
        order: 11,
        tableType: ETableConfig.Claims,
        controlName: "claim_Contact_Name",
        show: false
      },
      {
        order: 12,
        tableType: ETableConfig.Claims,
        controlName: "claim_Damaged_Estimate",
        show: false
      },
      {
        order: 13,
        tableType: ETableConfig.Claims,
        controlName: "claim_Paid",
        show: false
      },
      {
        order: 14,
        tableType: ETableConfig.Claims,
        controlName: "claim_Reserve_Payment",
        show: false
      },
      {
        order: 15,
        tableType: ETableConfig.Claims,
        controlName: "claim_Status_Change",
        show: false
      }
    ],
    [ETableConfig.ClientClaims]: [
      {
        order: 0,
        tableType: ETableConfig.ClientClaims,
        controlName: "claim_ID",
        show: true
      },
      {
        order: 1,
        tableType: ETableConfig.ClientClaims,
        controlName: "claim_Reference",
        show: true
      },
      {
        order: 2,
        tableType: ETableConfig.ClientClaims,
        controlName: "claim_IncidentDate",
        show: true
      },
      {
        order: 3,
        tableType: ETableConfig.ClientClaims,
        controlName: "claim_IncidentType",
        show: true
      },
      {
        order: 4,
        tableType: ETableConfig.ClientClaims,
        controlName: "claim_IncidentSubType",
        show: true
      },
      {
        order: 5,
        tableType: ETableConfig.ClientClaims,
        controlName: "claim_Total_Estimate",
        show: true
      },
      {
        order: 6,
        tableType: ETableConfig.ClientClaims,
        controlName: "latest_editing",
        show: true
      },
      {
        order: 7,
        tableType: ETableConfig.ClientClaims,
        controlName: "claim_Status",
        show: true
      },
      {
        order: 8,
        tableType: ETableConfig.ClientClaims,
        controlName: "controls",
        show: true
      },
      {
        order: 9,
        tableType: ETableConfig.ClientClaims,
        controlName: "claim_Contact_Email",
        show: false
      },
      {
        order: 10,
        tableType: ETableConfig.ClientClaims,
        controlName: "claim_Contact_Name",
        show: false
      },
      {
        order: 11,
        tableType: ETableConfig.ClientClaims,
        controlName: "claim_Damaged_Estimate",
        show: false
      },
      {
        order: 12,
        tableType: ETableConfig.ClientClaims,
        controlName: "claim_Paid",
        show: false
      },
      {
        order: 13,
        tableType: ETableConfig.ClientClaims,
        controlName: "claim_Reserve_Payment",
        show: false
      },
      {
        order: 14,
        tableType: ETableConfig.ClientClaims,
        controlName: "claim_Status_Change",
        show: false
      }
    ],
    [ETableConfig.Object]: [
      {
        order: 0,
        tableType: ETableConfig.Object,
        controlName: "CertificateIdent1",
        show: true
      },
      {
        order: 1,
        tableType: ETableConfig.Object,
        controlName: "CertificateIdent2",
        show: true
      },
      {
        order: 2,
        tableType: ETableConfig.Object,
        controlName: "CertificateIdent3",
        show: true
      },
      {
        order: 3,
        tableType: ETableConfig.Object,
        controlName: "IdSegmentation1",
        show: true
      },
      {
        order: 4,
        tableType: ETableConfig.Object,
        controlName: "certificateStatus",
        show: true
      },
      {
        order: 5,
        tableType: ETableConfig.Object,
        controlName: "CoverStart",
        show: true
      },
      {
        order: 6,
        tableType: ETableConfig.Object,
        controlName: "CoverEnd",
        show: true
      },
      {
        order: 7,
        tableType: ETableConfig.Object,
        controlName: "insuranceAnnualPremium",
        show: true
      },
      {
        order: 8,
        tableType: ETableConfig.Object,
        controlName: "CertificateIdent4",
        show: false
      },
      {
        order: 9,
        tableType: ETableConfig.Object,
        controlName: "Check1",
        show: false
      },
      {
        order: 10,
        tableType: ETableConfig.Object,
        controlName: "Check2",
        show: false
      },
      {
        order: 11,
        tableType: ETableConfig.Object,
        controlName: "Check3",
        show: false
      },
      {
        order: 12,
        tableType: ETableConfig.Object,
        controlName: "Check4",
        show: false
      },
      {
        order: 13,
        tableType: ETableConfig.Object,
        controlName: "Money1",
        show: false
      },
      {
        order: 14,
        tableType: ETableConfig.Object,
        controlName: "Money2",
        show: false
      },
      {
        order: 15,
        tableType: ETableConfig.Object,
        controlName: "insuranceWrittenPremium",
        show: false
      },
      {
        order: 16,
        tableType: ETableConfig.Object,
        controlName: "controls",
        show: true
      }
    ]
  };
  private _tableConfigsData: ITableConfigInfoList = {
    [ETableConfig.Insurance]: {
      "insuranceProduct": {
        data: "Sample text",
        dataType: ETableDataType.String,
        options: null
      },
      "note": {
        data: "Sample text",
        dataType: ETableDataType.String,
        options: null
      },
      "insurancePolicyNumber": {
        data: "0000-0000",
        dataType: ETableDataType.String,
        options: null
      },
      "insuranceCompany": {
        data: "Sample text",
        dataType: ETableDataType.String,
        options: null
      },
      "insuranceStart": {
        data: "2024-09-16T00:00:00",
        dataType: ETableDataType.Date,
        options: []
      },
      "insuranceRenewal": {
        data: "2024-09-16T00:00:00",
        dataType: ETableDataType.Date,
        options: [false, false, false, true]
      },
      "insuranceAnnualTotal": {
        data: 10500,
        dataType: ETableDataType.Money,
        options: [false]
      },
      "insuranceWrittenTotal": {
        data: 10500,
        dataType: ETableDataType.Money,
        options: [false]
      },
      "currency": {
        data: "USD",
        dataType: ETableDataType.String,
        options: null
      },
      "user_status": {
        data: "Sample status",
        dataType: ETableDataType.String,
        options: null
      },
      "controls": {
        data: "",
        dataType: ETableDataType.Controls,
        options: {
          edit: true,
          delete: true
        }
      },
      "insuranceFees": {
        data: 10500,
        dataType: ETableDataType.Money,
        options: [false]
      },
      "insuranceAnnualPremium": {
        data: 10500,
        dataType: ETableDataType.Money,
        options: [false]
      },
      "insuranceInstalments": {
        data: "Sample value",
        dataType: ETableDataType.String,
        options: null
      },
      "insuranceWrittenPremium": {
        data: 10500,
        dataType: ETableDataType.Money,
        options: [false]
      },
      "insuranceOriginalStart": {
        data: "2024-09-16T00:00:00",
        dataType: ETableDataType.Date,
        options: [false, false, false, true]
      },
      "renewal_Status": {
        data: "Sample value",
        dataType: ETableDataType.String,
        options: null
      }
    },
    [ETableConfig.Claims]: {
      "claim_ID": {
        data: "100000",
        dataType: ETableDataType.String,
        options: null
      },
      "claim_IncidentDate": {
        data: "2024-09-16T00:00:00",
        dataType: ETableDataType.Date,
        options: []
      },
      "claim_IncidentType": {
        data: "Sample text",
        dataType: ETableDataType.String,
        options: null
      },
      "claim_Insured": {
        data: "Sample insured",
        dataType: ETableDataType.String,
        options: null
      },
      "claim_IncidentSubType": {
        data: "Sample text",
        dataType: ETableDataType.String,
        options: null
      },
      "reference": {
        data: "Sample text",
        dataType: ETableDataType.String,
        options: null
      },
      "claim_Status": {
        data: "Sample status",
        dataType: ETableDataType.String,
        options: null
      },
      "claim_Total_Estimate": {
        data: 10500,
        dataType: ETableDataType.Money,
        options: [false]
      },
      "latest_editing": {
        data: "2024-09-16T00:00:00",
        dataType: ETableDataType.Date,
        options: []
      },
      "controls": {
        data: "",
        dataType: ETableDataType.Controls,
        options: {
          edit: false,
          delete: true
        }
      },
      "claim_Contact_Email": {
        data: "sample@mail",
        dataType: ETableDataType.String,
        options: null
      },
      "claim_Contact_Name": {
        data: "Sample Sample",
        dataType: ETableDataType.String,
        options: null
      },
      "claim_Damaged_Estimate": {
        data: 10500,
        dataType: ETableDataType.Money,
        options: [false]
      },
      "claim_Paid": {
        data: 10500,
        dataType: ETableDataType.Money,
        options: [false]
      },
      "claim_Reserve_Payment": {
        data: 10500,
        dataType: ETableDataType.Money,
        options: [false]
      },
      "claim_Status_Change": {
        data: "2024-09-16T00:00:00",
        dataType: ETableDataType.Date,
        options: [false, true, false, false]
      }
    },
    [ETableConfig.ClientClaims]: {
      "claim_ID": {
        data: "100000",
        dataType: ETableDataType.String,
        options: null
      },
      "claim_IncidentDate": {
        data: "2024-09-16T00:00:00",
        dataType: ETableDataType.Date,
        options: []
      },
      "claim_IncidentType": {
        data: "Sample text",
        dataType: ETableDataType.String,
        options: null
      },
      "claim_IncidentSubType": {
        data: "Sample text",
        dataType: ETableDataType.String,
        options: null
      },
      "claim_Reference": {
        data: "Sample text",
        dataType: ETableDataType.String,
        options: null
      },
      "claim_Status": {
        data: "Sample status",
        dataType: ETableDataType.String,
        options: null
      },
      "claim_Total_Estimate": {
        data: 10500,
        dataType: ETableDataType.Money,
        options: [false]
      },
      "latest_editing": {
        data: "2024-09-16T00:00:00",
        dataType: ETableDataType.Date,
        options: []
      },
      "controls": {
        data: "",
        dataType: ETableDataType.Controls,
        options: {
          edit: true,
          delete: false
        }
      },
      "claim_Contact_Email": {
        data: "sample@mail",
        dataType: ETableDataType.String,
        options: null
      },
      "claim_Contact_Name": {
        data: "Sample Sample",
        dataType: ETableDataType.String,
        options: null
      },
      "claim_Damaged_Estimate": {
        data: 10500,
        dataType: ETableDataType.Money,
        options: [false]
      },
      "claim_Paid": {
        data: 10500,
        dataType: ETableDataType.Money,
        options: [false]
      },
      "claim_Reserve_Payment": {
        data: 10500,
        dataType: ETableDataType.Money,
        options: [false]
      },
      "claim_Status_Change": {
        data: "2024-09-16T00:00:00",
        dataType: ETableDataType.Date,
        options: [false, true, false, false]
      }
    },
    [ETableConfig.Object]: {
      "CertificateIdent1": {
        data: "Sample text",
        dataType: ETableDataType.String,
        options: null
      },
      "CertificateIdent2": {
        data: "Sample text",
        dataType: ETableDataType.String,
        options: null
      },
      "CertificateIdent3": {
        data: "Sample text",
        dataType: ETableDataType.String,
        options: null
      },
      "IdSegmentation1": {
        data: "Sample text",
        dataType: ETableDataType.String,
        options: null
      },
      "certificateStatus": {
        data: "Sample text",
        dataType: ETableDataType.String,
        options: null
      },
      "CoverStart": {
        data: "2024-09-16T00:00:00",
        dataType: ETableDataType.Date,
        options: []
      },
      "CoverEnd": {
        data: "2024-09-16T00:00:00",
        dataType: ETableDataType.Date,
        options: []
      },
      "insuranceAnnualPremium": {
        data: 10500,
        dataType: ETableDataType.Money,
        options: [false]
      },
      "controls": {
        data: "",
        dataType: ETableDataType.Controls,
        options: {
          edit: true,
          delete: true
        }
      },
      "CertificateIdent4": {
        data: "Sample text",
        dataType: ETableDataType.String,
        options: null
      },
      "Check1": {
        data: true,
        dataType: ETableDataType.Boolean,
        options: null
      },
      "Check2": {
        data: false,
        dataType: ETableDataType.Boolean,
        options: null
      },
      "Check3": {
        data: false,
        dataType: ETableDataType.Boolean,
        options: null
      },
      "Check4": {
        data: true,
        dataType: ETableDataType.Boolean,
        options: null
      },
      "Money1": {
        data: 10500,
        dataType: ETableDataType.Money,
        options: [false]
      },
      "Money2": {
        data: 10500,
        dataType: ETableDataType.Money,
        options: [false]
      },
      "insuranceWrittenPremium": {
        data: 10500,
        dataType: ETableDataType.Money,
        options: [false]
      }
    }
  };
  // private _tableConfigs: BehaviorSubject<ITableConfigList> = new BehaviorSubject(this._tableConfigsDefault);
  private _tableConfigs: BehaviorSubject<ITableConfigList> = new BehaviorSubject(JSON.parse(localStorage.getItem("tableConfigs")));
  public tableConfigs$ = this._tableConfigs.asObservable();

  constructor(
    private _api: TableSettingsApiService,
    private _t: TranslateService
  ) {
  }

  public setTableConfigs(configs: ITableConfigList) {
    this._tableConfigs.next(configs);
  }

  public updateConfig(tableConfigs: ITableConfig[], tableType: ETableConfig): Observable<IApiResponse<any>> {
    this.setTableConfigs({...this._tableConfigs.getValue(), [tableType]: tableConfigs});
    localStorage.setItem("tableConfigs", JSON.stringify(this._tableConfigs.getValue()));
    return this._api.updateSettings(this._tableConfigs.getValue()[tableType]);
  }

  public getTableConfigs(tableType: ETableConfig): ITableConfigWithInfo[] {
    const tableConfigs = this._tableConfigs.getValue()?.[tableType] || [];
    if (!tableConfigs.length) {
      return [];
    }

    return tableConfigs.map(item => ({...item, info: this.getTableConfigData(item.tableType, item.controlName)}));
  }

  public getTableConfigsForTable(tableType: ETableConfig): ITableConfig[] {
    const tableConfigs = this._tableConfigs.getValue()?.[tableType] || [];

    if (!tableConfigs.length) {
      return [];
    }
    return tableConfigs.filter(col => !!col.show && (col.controlName === "controls" || col.tableType === ETableConfig.Object || this._t.instant(col.controlName + ".caption") !== ""));
  }

  public getTableConfigsDefault(tableType: ETableConfig): ITableConfigWithInfo[] {
    const tableConfigs = this._tableConfigsDefault[tableType];
    return tableConfigs.map(item => ({...item, info: this.getTableConfigData(item.tableType, item.controlName)}));
  }

  public getTableConfigData(tableType: ETableConfig, controlName: string): ITableConfigInfo {
    return this._tableConfigsData[tableType][controlName];
  }

  public fetchTableConfigs() {
    this._api.getSettings().pipe().subscribe(res => {
      if (res.data) {
        this._sortTableConfigs(res.data);
      }
    });
  }

  public initTableConfigsFromStorage() {
    if (localStorage.getItem("tableConfigs")) {
      this.setTableConfigs(JSON.parse(localStorage.getItem("tableConfigs")));
    } else {
      this.fetchTableConfigs();
    }
  }

  private _sortTableConfigs(tableConfigs: ITableConfig[]) {
    const res = {
      [ETableConfig.Insurance]: [],
      [ETableConfig.Claims]: [],
      [ETableConfig.ClientClaims]: [],
      [ETableConfig.Object]: []
    };
    tableConfigs.forEach(item => {
      res[item.tableType].push(item);
    });
    res[ETableConfig.Insurance] = res[ETableConfig.Insurance].sort((a, b) => a.order - b.order);
    res[ETableConfig.Claims] = res[ETableConfig.Claims].sort((a, b) => a.order - b.order);
    res[ETableConfig.ClientClaims] = res[ETableConfig.ClientClaims].sort((a, b) => a.order - b.order);
    res[ETableConfig.Object] = res[ETableConfig.Object].sort((a, b) => a.order - b.order);
    this.setTableConfigs(res);
    localStorage.setItem("tableConfigs", JSON.stringify(this._tableConfigs.getValue()));
  }
}
