import {Injectable} from "@angular/core";
import {IFormConfig, IFormConfigGroup, IFormConfigList} from "@shared/models/IFormConfig";
import {EFormConfig} from "@shared/enums/EFormConfig";
import {BehaviorSubject, Observable} from "rxjs";
import {FormSettingsApiService} from "@shared/api/form-settings/form-settings-api.service";
import {IApiResponse} from "@app/core";

@Injectable()
export class FormConfigService {
  private _formConfigsDefault: IFormConfigList = {
    [EFormConfig.Client]: [
      {
        row: 1,
        column: 1,
        order: 0,
        group: 0,
        formType: EFormConfig.Client,
        controlName: "insured_Tax_ID"
      },
      {
        row: 1,
        column: 1,
        order: 1,
        group: 0,
        formType: EFormConfig.Client,
        controlName: "insured_Name"
      },
      {
        row: 1,
        column: 1,
        order: 2,
        group: 0,
        formType: EFormConfig.Client,
        controlName: "insured_Address1"
      },
      {
        row: 1,
        column: 1,
        order: 3,
        group: 0,
        formType: EFormConfig.Client,
        controlName: "insured_Address2"
      },
      {
        row: 1,
        column: 1,
        order: 4,
        group: 0,
        formType: EFormConfig.Client,
        controlName: "insured_Town"
      },
      {
        row: 1,
        column: 1,
        order: 5,
        group: 0,
        formType: EFormConfig.Client,
        controlName: "insured_Zipcode"
      },
      {
        row: 1,
        column: 1,
        order: 6,
        group: 0,
        formType: EFormConfig.Client,
        controlName: "insured_Country"
      },
      {
        row: 1,
        column: 1,
        order: 7,
        group: 0,
        formType: EFormConfig.Client,
        controlName: "insured_Region"
      },
      {
        row: 1,
        column: 1,
        order: 8,
        group: 0,
        formType: EFormConfig.Client,
        controlName: "insuredTextVar"
      },
      {
        row: 1,
        column: 2,
        order: 0,
        group: 0,
        formType: EFormConfig.Client,
        controlName: "insured_Segmentation1"
      },
      {
        row: 1,
        column: 2,
        order: 1,
        group: 0,
        formType: EFormConfig.Client,
        controlName: "insured_Segmentation2"
      },
      {
        row: 1,
        column: 2,
        order: 2,
        group: 0,
        formType: EFormConfig.Client,
        controlName: "insured_Check1"
      },
      {
        row: 1,
        column: 2,
        order: 3,
        group: 0,
        formType: EFormConfig.Client,
        controlName: "insured_Check2"
      },
      {
        row: 1,
        column: 2,
        order: 4,
        group: 0,
        formType: EFormConfig.Client,
        controlName: "insured_Check3"
      },
      {
        row: 1,
        column: 2,
        order: 5,
        group: 0,
        formType: EFormConfig.Client,
        controlName: "insured_Check4"
      },
      {
        row: 1,
        column: 2,
        order: 6,
        group: 0,
        formType: EFormConfig.Client,
        controlName: "insured_Check5"
      },
      {
        row: 1,
        column: 2,
        order: 7,
        group: 0,
        formType: EFormConfig.Client,
        controlName: "insured_Phone"
      },
      {
        row: 1,
        column: 2,
        order: 8,
        group: 0,
        formType: EFormConfig.Client,
        controlName: "insured_Email"
      },
      {
        row: 1,
        column: 3,
        order: 0,
        group: 0,
        formType: EFormConfig.Client,
        controlName: "insured_Segmentation3"
      },
      {
        row: 1,
        column: 3,
        order: 1,
        group: 0,
        formType: EFormConfig.Client,
        controlName: "insured_Segmentation4"
      },
      {
        row: 1,
        column: 3,
        order: 2,
        group: 0,
        formType: EFormConfig.Client,
        controlName: "insured_Key_Account"
      },
      {
        row: 1,
        column: 3,
        order: 3,
        group: 0,
        formType: EFormConfig.Client,
        controlName: "insured_Second_Account"
      },
      {
        row: 1,
        column: 3,
        order: 4,
        group: 0,
        formType: EFormConfig.Client,
        controlName: "crM_category"
      },
      {
        row: 1,
        column: 3,
        order: 5,
        group: 0,
        formType: EFormConfig.Client,
        controlName: "insured_Bank_Name"
      },
      {
        row: 1,
        column: 3,
        order: 6,
        group: 0,
        formType: EFormConfig.Client,
        controlName: "insured_Bank_Regnr"
      },
      {
        row: 1,
        column: 3,
        order: 7,
        group: 0,
        formType: EFormConfig.Client,
        controlName: "insured_Bank_Account"
      },
      {
        row: 1,
        column: 3,
        order: 8,
        group: 0,
        formType: EFormConfig.Client,
        controlName: "insured_Invoice_Email"
      },
      {
        row: 1,
        column: 4,
        order: 0,
        group: 0,
        formType: EFormConfig.Client,
        controlName: "insured_ID"
      },
      {
        row: 1,
        column: 4,
        order: 1,
        group: 0,
        formType: EFormConfig.Client,
        controlName: "insured_IO_ref"
      },
      {
        row: 1,
        column: 4,
        order: 2,
        group: 0,
        formType: EFormConfig.Client,
        controlName: "insured_Finance_Ref1"
      },
      {
        row: 1,
        column: 4,
        order: 3,
        group: 0,
        formType: EFormConfig.Client,
        controlName: "insured_Incorporated"
      },
      {
        row: 1,
        column: 4,
        order: 4,
        group: 0,
        formType: EFormConfig.Client,
        controlName: "insuredDateVar"
      },
      {
        row: 1,
        column: 4,
        order: 5,
        group: 0,
        formType: EFormConfig.Client,
        controlName: "insuredDateVar2"
      },
      {
        row: 1,
        column: 4,
        order: 6,
        group: 0,
        formType: EFormConfig.Client,
        controlName: "insuredDateVar3"
      },
      {
        row: 1,
        column: 4,
        order: 7,
        group: 0,
        formType: EFormConfig.Client,
        controlName: "insured_Contract_Email"
      },
      {
        row: 2,
        column: 1,
        order: 0,
        group: 0,
        formType: EFormConfig.Client,
        controlName: "insured_Note"
      },
      {
        row: 2,
        column: 2,
        order: 0,
        group: 0,
        formType: EFormConfig.Client,
        controlName: "insuredNote2"
      }
    ],
    [EFormConfig.Insurance]: [
      {
        row: 1,
        column: 1,
        order: 0,
        group: 0,
        formType: EFormConfig.Insurance,
        controlName: "insuranceProduct"
      },
      {
        row: 1,
        column: 1,
        order: 1,
        group: 0,
        formType: EFormConfig.Insurance,
        controlName: "insuranceCompany"
      },
      {
        row: 1,
        column: 1,
        order: 2,
        group: 0,
        formType: EFormConfig.Insurance,
        controlName: "insurancePolicyNumber"
      },
      {
        row: 1,
        column: 1,
        order: 3,
        group: 0,
        formType: EFormConfig.Insurance,
        controlName: "insuranceStatus"
      },
      {
        row: 1,
        column: 2,
        order: 0,
        group: 0,
        formType: EFormConfig.Insurance,
        controlName: "insuranceOriginalStart"
      },
      {
        row: 1,
        column: 2,
        order: 1,
        group: 0,
        formType: EFormConfig.Insurance,
        controlName: "insuranceStart"
      },
      {
        row: 1,
        column: 2,
        order: 2,
        group: 0,
        formType: EFormConfig.Insurance,
        controlName: "insuranceEffectiveEnd"
      },
      {
        row: 1,
        column: 2,
        order: 3,
        group: 0,
        formType: EFormConfig.Insurance,
        controlName: "insuranceRenewal"
      },
      {
        row: 1,
        column: 3,
        order: 0,
        group: 0,
        formType: EFormConfig.Insurance,
        controlName: "insuranceQuoted"
      },
      {
        row: 1,
        column: 3,
        order: 1,
        group: 0,
        formType: EFormConfig.Insurance,
        controlName: "insuranceQuoteExpiration"
      },
      {
        row: 1,
        column: 3,
        order: 2,
        group: 0,
        formType: EFormConfig.Insurance,
        controlName: "insuranceRetroactive"
      },
      {
        row: 1,
        column: 3,
        order: 3,
        group: 0,
        formType: EFormConfig.Insurance,
        controlName: "insuranceExtendedCoverDate"
      },
      {
        row: 1,
        column: 4,
        order: 0,
        group: 0,
        formType: EFormConfig.Insurance,
        controlName: "insurance_Segmentation1"
      },
      {
        row: 1,
        column: 4,
        order: 1,
        group: 0,
        formType: EFormConfig.Insurance,
        controlName: "insurance_Segmentation2"
      },
      {
        row: 1,
        column: 4,
        order: 2,
        group: 0,
        formType: EFormConfig.Insurance,
        controlName: "renewal_Method"
      },
      {
        row: 1,
        column: 4,
        order: 3,
        group: 0,
        formType: EFormConfig.Insurance,
        controlName: "renewal_Status"
      },
      {
        row: 2,
        column: 1,
        order: 0,
        group: 0,
        formType: EFormConfig.Insurance,
        controlName: "insuranceConditions"
      },
      {
        row: 2,
        column: 2,
        order: 0,
        group: 0,
        formType: EFormConfig.Insurance,
        controlName: "insuranceDescription"
      },
      {
        row: 3,
        column: 1,
        order: 0,
        group: 0,
        formType: EFormConfig.Insurance,
        controlName: "insuranceRisk"
      },
      {
        row: 3,
        column: 2,
        order: 0,
        group: 0,
        formType: EFormConfig.Insurance,
        controlName: "insuranceNote"
      }
    ],
    [EFormConfig.Claim]: [
      {
        row: 1,
        column: 1,
        order: 0,
        group: 1,
        formType: EFormConfig.Claim,
        controlName: "claim_ID"
      },
      {
        row: 1,
        column: 1,
        order: 1,
        group: 1,
        formType: EFormConfig.Claim,
        controlName: "claim_Reference"
      },
      {
        row: 1,
        column: 1,
        order: 2,
        group: 1,
        formType: EFormConfig.Claim,
        controlName: "insured_Name"
      },
      {
        row: 1,
        column: 2,
        order: 0,
        group: 1,
        formType: EFormConfig.Claim,
        controlName: "claim_Reported"
      },
      {
        row: 1,
        column: 2,
        order: 1,
        group: 1,
        formType: EFormConfig.Claim,
        controlName: "claim_IncidentDate"
      },
      {
        row: 1,
        column: 2,
        order: 2,
        group: 1,
        formType: EFormConfig.Claim,
        controlName: "claim_IncidentTime"
      },
      {
        row: 1,
        column: 3,
        order: 0,
        group: 1,
        formType: EFormConfig.Claim,
        controlName: "claim_Description"
      },
      {
        row: 1,
        column: 1,
        order: 0,
        group: 2,
        formType: EFormConfig.Claim,
        controlName: "claim_IncidentType"
      },
      {
        row: 1,
        column: 1,
        order: 1,
        group: 2,
        formType: EFormConfig.Claim,
        controlName: "claim_IncidentSubType"
      },
      {
        row: 1,
        column: 2,
        order: 0,
        group: 2,
        formType: EFormConfig.Claim,
        controlName: "claim_Damaged_Text"
      },
      {
        row: 1,
        column: 3,
        order: 0,
        group: 2,
        formType: EFormConfig.Claim,
        controlName: "claim_Damaged_Estimate"
      },
      {
        row: 1,
        column: 1,
        order: 0,
        group: 3,
        formType: EFormConfig.Claim,
        controlName: "claim_Segmentation1"
      },
      {
        row: 1,
        column: 1,
        order: 1,
        group: 3,
        formType: EFormConfig.Claim,
        controlName: "claim_Segmentation2"
      },
      {
        row: 1,
        column: 1,
        order: 2,
        group: 3,
        formType: EFormConfig.Claim,
        controlName: "claim_Certificateholder"
      },
      {
        row: 1,
        column: 1,
        order: 3,
        group: 3,
        formType: EFormConfig.Claim,
        controlName: "claim_RM_Object"
      },
      {
        row: 1,
        column: 1,
        order: 4,
        group: 3,
        formType: EFormConfig.Claim,
        controlName: "claim_Segmentation3"
      },
      {
        row: 1,
        column: 2,
        order: 0,
        group: 3,
        formType: EFormConfig.Claim,
        controlName: "claim_Contact_Name"
      },
      {
        row: 1,
        column: 2,
        order: 1,
        group: 3,
        formType: EFormConfig.Claim,
        controlName: "claim_Contact_Email"
      },
      {
        row: 1,
        column: 2,
        order: 2,
        group: 3,
        formType: EFormConfig.Claim,
        controlName: "note"
      },
      {
        row: 1,
        column: 3,
        order: 0,
        group: 3,
        formType: EFormConfig.Claim,
        controlName: "claim_Status"
      },
      {
        row: 1,
        column: 3,
        order: 1,
        group: 3,
        formType: EFormConfig.Claim,
        controlName: "claim_Status_Change"
      },
      {
        row: 1,
        column: 3,
        order: 2,
        group: 3,
        formType: EFormConfig.Claim,
        controlName: "claim_Contact_Alerts"
      },
      {
        row: 1,
        column: 1,
        order: 0,
        group: 4,
        formType: EFormConfig.Claim,
        controlName: "claim_Total"
      },
      {
        row: 1,
        column: 1,
        order: 1,
        group: 4,
        formType: EFormConfig.Claim,
        controlName: "claim_Reserve_Payment"
      },
      {
        row: 1,
        column: 2,
        order: 0,
        group: 4,
        formType: EFormConfig.Claim,
        controlName: "claim_Paid"
      },
      {
        row: 1,
        column: 2,
        order: 1,
        group: 4,
        formType: EFormConfig.Claim,
        controlName: "claim_Reserve_Recovery"
      },
      {
        row: 1,
        column: 3,
        order: 0,
        group: 4,
        formType: EFormConfig.Claim,
        controlName: "claim_Recovered"
      },
      {
        row: 1,
        column: 3,
        order: 1,
        group: 4,
        formType: EFormConfig.Claim,
        controlName: "claim_Excess"
      }
    ],
    [EFormConfig.Object]: [
      {
        row: 1,
        column: 1,
        order: 0,
        group: 0,
        formType: EFormConfig.Object,
        controlName: "certificateIdent1"
      },
      {
        row: 1,
        column: 1,
        order: 1,
        group: 0,
        formType: EFormConfig.Object,
        controlName: "externalApiRef"
      },
      {
        row: 1,
        column: 1,
        order: 2,
        group: 0,
        formType: EFormConfig.Object,
        controlName: "certificateIdent2"
      },
      {
        row: 1,
        column: 1,
        order: 3,
        group: 0,
        formType: EFormConfig.Object,
        controlName: "certificateIdent3"
      },
      {
        row: 1,
        column: 1,
        order: 4,
        group: 0,
        formType: EFormConfig.Object,
        controlName: "certificateIdent4"
      },
      {
        row: 1,
        column: 1,
        order: 5,
        group: 0,
        formType: EFormConfig.Object,
        controlName: "date1"
      },
      {
        row: 1,
        column: 1,
        order: 6,
        group: 0,
        formType: EFormConfig.Object,
        controlName: "date2"
      },
      {
        row: 1,
        column: 2,
        order: 0,
        group: 0,
        formType: EFormConfig.Object,
        controlName: "certificateIdent5"
      },
      {
        row: 1,
        column: 2,
        order: 1,
        group: 0,
        formType: EFormConfig.Object,
        controlName: "certificateIdent6"
      },
      {
        row: 1,
        column: 2,
        order: 2,
        group: 0,
        formType: EFormConfig.Object,
        controlName: "certificateCountry"
      },
      {
        row: 1,
        column: 2,
        order: 3,
        group: 0,
        formType: EFormConfig.Object,
        controlName: "certificateIdent7"
      },
      {
        row: 1,
        column: 2,
        order: 4,
        group: 0,
        formType: EFormConfig.Object,
        controlName: "certificateIdent8"
      },
      {
        row: 1,
        column: 2,
        order: 5,
        group: 0,
        formType: EFormConfig.Object,
        controlName: "certificateIdent9"
      },
      {
        row: 1,
        column: 2,
        order: 6,
        group: 0,
        formType: EFormConfig.Object,
        controlName: "certificateIdent10"
      },
      {
        row: 1,
        column: 3,
        order: 0,
        group: 0,
        formType: EFormConfig.Object,
        controlName: "check1"
      },
      {
        row: 1,
        column: 3,
        order: 1,
        group: 0,
        formType: EFormConfig.Object,
        controlName: "check2"
      },
      {
        row: 1,
        column: 3,
        order: 2,
        group: 0,
        formType: EFormConfig.Object,
        controlName: "check3"
      },
      {
        row: 1,
        column: 3,
        order: 3,
        group: 0,
        formType: EFormConfig.Object,
        controlName: "check4"
      },
      {
        row: 1,
        column: 3,
        order: 4,
        group: 0,
        formType: EFormConfig.Object,
        controlName: "check5"
      },
      {
        row: 1,
        column: 3,
        order: 5,
        group: 0,
        formType: EFormConfig.Object,
        controlName: "check6"
      },
      {
        row: 2,
        column: 1,
        order: 0,
        group: 0,
        formType: EFormConfig.Object,
        controlName: "int1"
      },
      {
        row: 2,
        column: 1,
        order: 1,
        group: 0,
        formType: EFormConfig.Object,
        controlName: "int2"
      },
      {
        row: 2,
        column: 1,
        order: 2,
        group: 0,
        formType: EFormConfig.Object,
        controlName: "int3"
      },
      {
        row: 2,
        column: 1,
        order: 3,
        group: 0,
        formType: EFormConfig.Object,
        controlName: "int4"
      },
      {
        row: 2,
        column: 1,
        order: 4,
        group: 0,
        formType: EFormConfig.Object,
        controlName: "int5"
      },
      {
        row: 2,
        column: 1,
        order: 5,
        group: 0,
        formType: EFormConfig.Object,
        controlName: "int6"
      },
      {
        row: 2,
        column: 1,
        order: 6,
        group: 0,
        formType: EFormConfig.Object,
        controlName: "int7"
      },
      {
        row: 2,
        column: 1,
        order: 7,
        group: 0,
        formType: EFormConfig.Object,
        controlName: "int8"
      },
      {
        row: 2,
        column: 1,
        order: 8,
        group: 0,
        formType: EFormConfig.Object,
        controlName: "money1"
      },
      {
        row: 2,
        column: 1,
        order: 9,
        group: 0,
        formType: EFormConfig.Object,
        controlName: "money2"
      },
      {
        row: 2,
        column: 1,
        order: 10,
        group: 0,
        formType: EFormConfig.Object,
        controlName: "insuranceWrittenPremium"
      },
      {
        row: 2,
        column: 1,
        order: 11,
        group: 0,
        formType: EFormConfig.Object,
        controlName: "insuranceAnnualPremium"
      },
      {
        row: 2,
        column: 1,
        order: 12,
        group: 0,
        formType: EFormConfig.Object,
        controlName: "certificateNote"
      },
      {
        row: 2,
        column: 2,
        order: 0,
        group: 0,
        formType: EFormConfig.Object,
        controlName: "idSegmentation1"
      },
      {
        row: 2,
        column: 2,
        order: 1,
        group: 0,
        formType: EFormConfig.Object,
        controlName: "idSegmentation2"
      },
      {
        row: 2,
        column: 2,
        order: 2,
        group: 0,
        formType: EFormConfig.Object,
        controlName: "idSegmentation3"
      },
      {
        row: 2,
        column: 2,
        order: 3,
        group: 0,
        formType: EFormConfig.Object,
        controlName: "idSegmentation4"
      },
      {
        row: 2,
        column: 2,
        order: 4,
        group: 0,
        formType: EFormConfig.Object,
        controlName: "idSegmentation5"
      },
      {
        row: 2,
        column: 2,
        order: 5,
        group: 0,
        formType: EFormConfig.Object,
        controlName: "idSegmentation6"
      },
      {
        row: 2,
        column: 2,
        order: 6,
        group: 0,
        formType: EFormConfig.Object,
        controlName: "idSegmentation7"
      },
      {
        row: 2,
        column: 2,
        order: 7,
        group: 0,
        formType: EFormConfig.Object,
        controlName: "idSegmentation8"
      },
      {
        row: 2,
        column: 2,
        order: 8,
        group: 0,
        formType: EFormConfig.Object,
        controlName: "idSegmentation9"
      },
      {
        row: 2,
        column: 2,
        order: 9,
        group: 0,
        formType: EFormConfig.Object,
        controlName: "idSegmentation10"
      },
      {
        row: 2,
        column: 3,
        order: 0,
        group: 0,
        formType: EFormConfig.Object,
        controlName: "coverStart"
      },
      {
        row: 2,
        column: 3,
        order: 1,
        group: 0,
        formType: EFormConfig.Object,
        controlName: "coverEnd"
      },
      {
        row: 2,
        column: 3,
        order: 2,
        group: 0,
        formType: EFormConfig.Object,
        controlName: "certificateStatus"
      }
    ]
  };
  private _formConfigs: BehaviorSubject<IFormConfigList> = new BehaviorSubject(JSON.parse(localStorage.getItem("formConfigs")));
  public formConfigs$ = this._formConfigs.asObservable();

  constructor(
    private _api: FormSettingsApiService
  ) {
  }

  public setFormConfigs(configs: IFormConfigList) {
    this._formConfigs.next(configs);
  }

  public updateConfig(formConfigs: IFormConfig[], entity: EFormConfig): Observable<IApiResponse<any>> {
    this.setFormConfigs({...this._formConfigs.getValue(), [entity]: formConfigs});
    localStorage.setItem("formConfigs", JSON.stringify(this._formConfigs.getValue()));
    return this._api.updateSettings(this._formConfigs.getValue()[entity]);
  }

  public getFormConfigs(entity: EFormConfig): IFormConfig[] {
    return this._formConfigs.getValue() && this._formConfigs.getValue()[entity]
      ? this._formConfigs.getValue()[entity]
      : [];
  }

  public getFormConfigsDefault(entity: EFormConfig): IFormConfig[] {
    return this._formConfigsDefault[entity];
  }

  public getConfigForTemplate(entity: EFormConfig): IFormConfigGroup[] {
    let result: IFormConfigGroup[] = [];

    this.getFormConfigs(entity).forEach(item => {
      let groupObj = result.find(r => r.group === item.group);
      if (!groupObj) {
        groupObj = {group: item.group, rows: []};
        result.push(groupObj);
      }
      let rowObj = groupObj.rows.find(c => c.row === item.row);
      if (!rowObj) {
        rowObj = {row: item.row, columns: []};
        groupObj.rows.push(rowObj);
      }

      // Find the column object in the row object or create it if it doesn't exist
      let colObj = rowObj.columns.find(c => c.column === item.column);
      if (!colObj) {
        colObj = {column: item.column, fields: []};
        rowObj.columns.push(colObj);
      }

      // Add the field to the column object
      colObj.fields.push({order: item.order, controlName: item.controlName});
      colObj.fields.sort((a, b) => a.order - b.order);
    });

    result.forEach(group => {
      // Ensure each row has all columns from 1 to max col number
      group.rows.forEach(row => {
        let maxCol = Math.max(...row.columns.map(c => c.column));
        for (let i = 1; i <= maxCol; i++) {
          if (!row.columns.find(c => c.column === i)) {
            row.columns.push({column: i, fields: []});
          }
        }
        // Sort columns by col number
        row.columns.sort((a, b) => a.column - b.column);
      });

      // Sort rows by row number
      group.rows.sort((a, b) => a.row - b.row);
    });

    // Sort rows by row number
    result.sort((a, b) => a.group - b.group);
    result = result.map(group => {
      return {
        group: group.group,
        rows: group.rows.filter(f => f.row !== 0)
      };
    });
    return result;
  }

  public fetchFormConfigs() {
    this._api.getSettings().pipe().subscribe(res => {
      if (res.data) {
        this._sortFormConfigs(res.data);
      }
    });
  }

  public initFormConfigsFromStorage() {
    if (localStorage.getItem("formConfigs")) {
      this.setFormConfigs(JSON.parse(localStorage.getItem("formConfigs")));
    } else {
      this.fetchFormConfigs();
    }
  }

  private _sortFormConfigs(formConfigs: IFormConfig[]) {
    const res = {
      [EFormConfig.Client]: [],
      [EFormConfig.Insurance]: [],
      [EFormConfig.Claim]: [],
      [EFormConfig.Object]: []
    };
    formConfigs.forEach(item => {
      res[item.formType].push(item);
    });
    this.setFormConfigs(res);
    localStorage.setItem("formConfigs", JSON.stringify(this._formConfigs.getValue()));
  }
}
