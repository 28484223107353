import {IGroup} from "./IGroup";
import {EInterfaceLayout, EInterfaceSize} from "@shared/enums/EInterface";

export interface IUser {
  active: boolean;
  currentGroup: IGroup;
  currentDomain: string;
  groups: IGroup[];
  idLogin: string;
  idRole: number;
  idUser: number;
  permissions: number[];
  region: string;
  isGuest: boolean;
  isClientLogin: boolean;
  roles: IUserRole[];
  status: number;
  userInfo: IUserInfo;
  token: string;
  refreshToken: string;
  expired: Date;
  uwSegmentation: boolean;
  isThirdPartyLogin: boolean;
}

export interface IUserInfo {
  authyId: string;
  defaultDocumentPeriod: number;
  defaultDocumentView: boolean;
  defaultIncludeInsurances: boolean;
  defaultSearchScope: number;
  emailFooter: string;
  emailPrimary: string;
  emailVerified: boolean;
  ioReference: string;
  is2FaRequired: boolean;
  isAuthyRegisterCompleted: boolean;
  isAutomatedTimetrackEnabled: boolean;
  name: string;
  passwordExpirationDate: Date;
  phoneMobile: string;
  privacyPolicyAccepted: boolean;
  region: string;
  status: number;
  timeOffset: number;
  title: string;
  useDarkTheme: boolean;
  widgetsConfig: string;
  size: EInterfaceSize;
  layout: EInterfaceLayout;
}

interface IUserRole {
  id: number;
  name: string;
}

export interface IUserProfile {
  idForwardNotifications: string;
  emailPrimary: string;
  emailVerified: boolean;
  emailSecondary: string;
  phoneMobile: string;
  name: string;
  title: string;
  status: number;
  region: string;
  emailFooter: string;
  ioReference: string;
  timeOffset: number;
  authyId: string;
  defaultDocumentView: boolean;
  showOnlyUserTasks: boolean;
  defaultIncludeInsurances: boolean;
  defaultDocumentPeriod: number;
  id: string;
  layout: EInterfaceLayout;
  size: EInterfaceSize;
}

export interface IUserProfileUpdate {
  id: string;
  idForwardNotifications: string;
  title: string;
  defaultDocumentView: true,
  phoneMobile: string;
  region: string;
  status: number;
  timeOffset: number;
  name: string;
  emailSecondary: string;
  emailFooter: string;
  ioReference: string;
  showOnlyUserTasks: boolean;
  defaultIncludeInsurances: boolean;
  defaultDocumentPeriod: number;
}
